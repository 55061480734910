import _Vue from 'vue';

// @ts-ignore
import Vuetify, { VInput, VTextField } from 'vuetify/lib';
import type { UserVuetifyPreset } from 'vuetify';
import VueGtm, { VueGtmUseOptions } from 'vue-gtm';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import EmailEditor from 'vue-email-editor';


import LabeledSection from './components/labeled-section.vue';
import QuantityInput from './components/quantity-input.vue';

// Import global CSS and Fonts:
import 'typeface-roboto';
import '@fortawesome/fontawesome-pro/css/all.css';

import 'coalesce-vue-vuetify2/dist/coalesce-vue-vuetify.css';
import '@common/shared.scss';

import $metadata from '@common/metadata.g';
// // viewmodels.g has sideeffects - it populates the global lookup on ViewModel and ListViewModel.
import '@common/viewmodels.g';
import CoalesceVuetify from 'coalesce-vue-vuetify2/lib';

// For Google Analytics/GTM/etc.
declare global {
  interface Window {
    dataLayer: {}[];
    appInsights?: ApplicationInsights;
  }
}
window.dataLayer = window.dataLayer || [];

declare module 'vue/types/vue' {
  export interface Vue {
    _isDestroyed?: boolean;
    readonly APP_NAME: string;
    readonly APP_TID: number;
    readonly APP_SUPPORT_EMAIL: string;
    readonly APP_SUPPORT_PHONE: string;
    readonly BANK_STATEMENT_NOTIFICATION_ENABLED: string;
    readonly SHOW_REMAINING_SEATS: string;
    readonly fbq: (...arg: any[]) => void;
  }
}

export interface TicketingCommonPluginOptions {
  publicUser: boolean;
}

export default function TicketingCommonPlugin(
  Vue: typeof _Vue,
  options: TicketingCommonPluginOptions
): void {
  Vue.use(Vuetify, {
    components: {
      // Global reg needed for v-currency-field
      VTextField,
    },
  });

  // Truncate plugin
  const filter = function(text: string, length: number | undefined, clamp: string) {
    clamp = clamp || '...';
    const node = document.createElement('div');
    node.innerHTML = text;
    const content = node.textContent;
    return content!.length > length! ? content!.slice(0, length) + clamp : content;
  };

  Vue.filter('truncate', filter);

  Vue.prototype.APP_NAME = APP_NAME;
  Vue.prototype.APP_TID = APP_TID;
  Vue.prototype.BANK_STATEMENT_NOTIFICATION_ENABLED = BANK_STATEMENT_NOTIFICATION_ENABLED;
  Vue.prototype.APP_SUPPORT_EMAIL = APP_SUPPORT_EMAIL;
  Vue.prototype.APP_SUPPORT_PHONE = APP_SUPPORT_PHONE;
  Vue.prototype.SHOW_REMAINING_SEATS = SHOW_REMAINING_SEATS;

  window.dataLayer.push({
    isPublicUser: options.publicUser,
    userId: USERNAME || null,
  });
  Vue.use(VueGtm, <VueGtmUseOptions>{
    id: process.env.VUE_APP_GTM_ID,
  });

  if (APPINSIGHTS_INSTRUMENTATIONKEY) {
    const appInsights = (window.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: APPINSIGHTS_INSTRUMENTATIONKEY,
        disableFetchTracking: false,
        enableAutoRouteTracking: true,
      },
    }));
    appInsights.loadAppInsights();
    appInsights.setAuthenticatedUserContext(USERNAME);
    appInsights.trackPageView();
  }

  // Stick in our own preferred defaults for vuetify components.
  // @ts-ignore
  VInput.options.props.dense.default = true;
  // @ts-ignore
  VTextField.options.props.dense.default = true;
  // @ts-ignore
  VTextField.options.props.outlined.default = true;
  // @ts-ignore
  VTextField.options.props.hideDetails.default = 'auto';

  // Shared, globally registered components.
  Vue.component('labeled-section', LabeledSection);
  Vue.component('quantity-input', QuantityInput);

  // // SETUP: coalesce-vue-vuetify
  Vue.use(CoalesceVuetify, {
    metadata: $metadata,
  });

  Vue.config.productionTip = false;
}

export const VuetifyOptions = <UserVuetifyPreset>{
  components: {
    EmailEditor,
  },
  icons: {
    iconfont: 'fa', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: COLOR_PRIMARY,
        secondary: COLOR_SECONDARY,
        accent: COLOR_ACCENT,

        error: '#df323b', // This is the default error color with darken-1
      },
    },
  },
};
