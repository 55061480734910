/* unplugin-vue-components disabled */import { CLoaderStatus as __unplugin_components_1 } from 'coalesce-vue-vuetify2/lib';
import { CListPagination as __unplugin_components_0 } from 'coalesce-vue-vuetify2/lib';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"event-dates-list-container"},[_c('v-sheet',{staticClass:"pt-1 pb-1",attrs:{"elevation":"2","tile":""}},[_c('v-row',{staticClass:"mx-3 pb-2"},[_c(__unplugin_components_0,{staticClass:"header-pagination flex-grow-1",attrs:{"pageSizes":[10, 25, 50, 100],"list":_vm.viewModel}})],1)],1),_vm._v(" "),_c(__unplugin_components_1,{attrs:{"loaders":{ 'no-initial-content': [_vm.viewModel.$load] }},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{ref:"stickyHeader",staticClass:"date-heading-row",staticStyle:{"position":"fixed","width":"100%","height":"48px","top":"48px","padding":"0 16px","display":"none","z-index":"1","align-items":"center","border-top-style":"solid","border-bottom-style":"solid","border-width":"1px","transition":"0.2s cubic-bezier(0.4, 0, 0.2, 1) top"}}),_vm._v(" "),_c('v-simple-table',{staticClass:"event-dates-list"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"width":"200px"}},[_vm._v("Event Name")]),_vm._v(" "),_c('th',{staticClass:"text-left",attrs:{"width":""}},[_vm._v("Description")]),_vm._v(" "),_c('th',{staticClass:"text-left"},[_vm._v("Time")]),_vm._v(" "),_c('th',{staticClass:"text-left"},[_vm._v("Duration")]),_vm._v(" "),(!_vm.$isPublicUser)?_c('th',{staticStyle:{"width":"10%"}},[_vm._v("Hidden from Public Web after initial purchase")]):_vm._e(),_vm._v(" "),(_vm.hasDescriptors)?_c('th',[_vm._v("Status")]):_vm._e(),_vm._v(" "),_c('th',{attrs:{"width":"1%"}})])]),_vm._v(" "),_c('tbody',[_vm._l((_vm.eventDatesByDate),function(eventDates,dateString){return [_c('tr',{key:dateString,staticClass:"date-heading-row"},[_c('td',{attrs:{"colspan":"100"}},[_vm._v("\n              "+_vm._s(_vm.format(
                  eventDates[0].eventStartDateTimeLocal,
                  'EEEE, MMMM d, yyyy'
                ))+"\n            ")])]),_vm._v(" "),_vm._l((eventDates),function(eventDate){return _c('tr',{key:eventDate.id,staticClass:"event-date-row"},[_c('td',{staticClass:"body-2 py-3",attrs:{"data-label":"Event"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("\n                "+_vm._s(eventDate.eventName)+"\n              ")])]),_vm._v(" "),_c('td',{staticClass:"caption py-1",attrs:{"data-label":"Description"}},[(
                  eventDate.eventShortDescription &&
                  eventDate.eventLongDescription &&
                  eventDate.eventShortDescription !=
                    eventDate.eventLongDescription
                )?_c('show-more',{scopedSlots:_vm._u([{key:"less",fn:function(){return [_c('span',{staticStyle:{"white-space":"pre-wrap"},domProps:{"textContent":_vm._s(eventDate.eventShortDescription.trim())}})]},proxy:true},{key:"more",fn:function(){return [_c('span',{staticStyle:{"white-space":"pre-wrap"},domProps:{"textContent":_vm._s(eventDate.eventLongDescription.trim())}})]},proxy:true}],null,true)}):_c('span',{staticStyle:{"white-space":"pre-wrap"},domProps:{"textContent":_vm._s(
                  (
                    eventDate.eventShortDescription ||
                    eventDate.eventLongDescription ||
                    ''
                  ).trim()
                )}})],1),_vm._v(" "),_c('td',{staticClass:"text-no-wrap",attrs:{"data-label":"Start Time"}},[_vm._v("\n              "+_vm._s(_vm.lightFormat(eventDate.eventStartDateTimeLocal, 'h:mm a'))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"text-no-wrap",attrs:{"data-label":"Duration"}},[_vm._v("\n              "+_vm._s(_vm.formatMinutes(eventDate.durationMinutes))+"\n            ")]),_vm._v(" "),(!_vm.$isPublicUser)?_c('td',{staticClass:"text-center text-no-wrap",attrs:{"data-label":"Hidden from public view"}},[(eventDate.hiddenFromInitialPurchase)?_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-check")]):_c('v-icon',{attrs:{"small":""}},[_vm._v("fa fa-x")])],1):_vm._e(),_vm._v(" "),(_vm.hasDescriptors)?_c('td',{attrs:{"data-label":"Status"}},[_vm._t("list-item-descriptors",null,{"eventDate":eventDate})],2):_vm._e(),_vm._v(" "),_c('td',{staticClass:"text-no-wrap",attrs:{"data-label":"Actions"}},[_vm._t("list-item-actions",null,{"eventDate":eventDate})],2)])})]})],2)])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }