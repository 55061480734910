export enum AppRoles {
  Accounting = 'Accounting/financial user',
  Admin = 'Admin',
  Agent = 'Agent',
  Consignee = 'Consignee',
  Consumer = 'Consumer',
  DashboardUser = 'Dashboard user',
  EventManager = 'Event manager/Ticket manager',
  Marketing = 'Marketing user',
  TemplateEditor = 'TemplateEditor',
  TenantAdmin = 'TenantAdmin',
  CentralReportAccountant = 'Central Report Accountant',
  Scanner = 'Scanner',
}
